const fantasticFurnitureProd = {
    search: {
        inputBox: ".SearchBox .search-box-container .searchbox .main-search input",
        queryBtn: ".SearchBox .search-box-container .searchbox  .search-icon",
        queryLocatorFromUrl: {
            afterLocator: "search",
          },
    },
    productClickGrid: {
        productElemWrapper: "ff-product-list-page .product-container",
        productPidAttr: "data-pid",
        isVariant: true,
        productVidAttr: "data-vid",
        source: "PRODUCT_CLICK_GRID",
        immediate: true,
    },
    addToCartFromSRP: {
        addToCartBtnSelector: ".button-container  button.cartBtn",
        source: "ADD_TO_CART_SRP",
    },
    addToCartFromPDP: {
        addToCartBtnSelector: ".quantity-cart-container button.actionBtn.actionBtn--cartBtn",
        pidSelector: ".ProductInformationTop .container",
        productPidAttr: "data-pid",
        qtySelector: ".quantity-cart-container input#PDPProductQty",
        isVariant: true,
        vidSelector: ".ProductInformationTop .container",
        productVidAttr: "data-vid",
        source: "ADD_TO_CART_PDP"
    },
    addToCartFromCartPage: {
        cartItemWrapper: ".cart-product-list-wrapper .product-data-container",
        productPidAttr: "data-pid",
        qtySelector: ".quantity-price-section .counter input",
        qtyPlusSelector: '.counter .fa-plus',
        qtyMinusSelector: '.counter .fa-minus',
        getQtyFromCB: function (qty, selectorType) {
            if (selectorType === "QTY_PLUS") {
                return (parseInt(qty) + 1).toString();
            } else if (selectorType === "QTY_MINUS") {
                return (parseInt(qty) - 1).toString();
            } else {
                return qty.toString();
            }
        },
        isVariant: true,
        productVidAttr: "data-vid",
        source: "ADD_TO_CART_FROM_CART_PAGE"
    },
    pageView: {
        delay: "1500",
        events: {
            home: {
                uniqueSelectors: {
                    selectors: [".MAIN_PROMO_RES_BANNER"]
                },
                urlIdentifiers: {
                    urls: ["https://www.fantasticfurniture.com.au/"],
                    exactMatch: true
                }   
            },
            search: {
                uniqueSelectors: {
                    selectors: [".container .search-result-container"]
                },
                urlIdentifiers: {
                    urls: ["/search/"],
                    exactMatch: false
                },
                searchRecall: {
                    noOfResultsSelector: ".SearchResultList .pagination-filter",
                    getSRCountCB: function (str) {
                        const match = str.match(/(\d+)$/);
                        const number = match ? match[1] : null;
                        return number;
                     }
                },  
            },
            category: {
                uniqueSelectors: {
                    selectors: [".CategoryName.has-components"]
                },
                urlIdentifiers: {
                    urls: ["/Categories/"],
                    exactMatch: false,
                }
            },
            productDisplay: {
                uniqueSelectors: {
                    selectors: [".ng-star-inserted.ProductDetailsPageTemplate"]
                }
            },
            cart: {
                uniqueSelectors: {
                    selectors: [".ng-star-inserted.CartPageTemplate"]
                },
                urlIdentifiers: {
                    urls: ["/cart"],
                    exactMatch: false
                }
            },
            order: {
                urlIdentifiers: {
                    urls: ["/order-confirmation"],
                    exactMatch: false,
                },
            }
          }
        } 
}


export default fantasticFurnitureProd;